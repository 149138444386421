import React from 'react';
import styled, { useTheme } from 'styled-components';

import moment from 'moment';
import { useIntl } from 'react-intl';

import Typography from 'app/ui/components/atoms/typography';

export type ChangelogEntry = {
  version: string,
  localeKey: string,
};

type ChangelogListProps = {
  title: string,
  items: string[],
};

type Props = {
  recentUpdates: ChangelogEntry[],
  knownIssues: ChangelogEntry[],
  comingSoon: string[],
};

const ChangelogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const ChangelogListWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.faqGrey};
  border-radius: 1rem;
  padding: 1.5rem;
  gap: 0.5rem;
`;

const ChangelogListHeader = styled(Typography)`
  margin-bottom: 0.5rem;
`;

const ChangelogUnorderedList = styled.ul`
  margin-left: 1.5rem;
`;

const ChangelogList = ({ title, items }: ChangelogListProps) => {
  const theme = useTheme();

  if (items.length === 0) {
    return null;
  }

  return (
    <ChangelogListWrapper>
      <ChangelogListHeader variant="h5">{title}</ChangelogListHeader>
      <ChangelogUnorderedList>
        {items.map((item) => (
          <li>
            <Typography variant="body2" color={theme.colors.paragraphGray}>
              {item}
            </Typography>
          </li>
        ))}
      </ChangelogUnorderedList>
    </ChangelogListWrapper>
  );
};

const Changelog = ({ recentUpdates, knownIssues, comingSoon }: Props) => {
  const intl = useIntl();

  const updatesTitle = intl.formatMessage({ id: 'releases.labels.updates' });
  const issuesTitle = intl.formatMessage({ id: 'releases.labels.issues' });
  const soonTitle = intl.formatMessage({ id: 'releases.labels.soon' });

  const recentUpdatesFormatted = recentUpdates.map(
    ({ version, localeKey }) =>
      `v${version} - ${intl.formatMessage({ id: localeKey })}`,
  );
  const knownIssuesFormatted = knownIssues.map(
    ({ version, localeKey }) =>
      `v${version} - ${intl.formatMessage({ id: localeKey })}`,
  );
  return (
    <ChangelogWrapper>
      <ChangelogList title={updatesTitle} items={recentUpdatesFormatted} />
      <ChangelogList title={issuesTitle} items={knownIssuesFormatted} />
      <ChangelogList title={soonTitle} items={comingSoon} />
    </ChangelogWrapper>
  );
};

export default Changelog;
